.imageLogin{
    background-image: url("../Img/whiteCat.jpg")!important;
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
  }
  
  .imageUsuarios{
    background-image: url("../Img/whiteCat.jpg")!important;
  }
  