@font-face {
  font-family: brushtip_c;
  src: url(../Fonts/Brushtip.ttf);
}
@font-face {
    font-family: novaScript;
    src: url(../Fonts/NovaScript.ttf);
  }
.makeStyles-underline-56::after{
  border-color:none;
}